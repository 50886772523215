var columns = [{
  title: '地址',
  dataIndex: 'address',
  key: 'address',
  width: '30%',
  scopedSlots: {
    customRender: 'address'
  }
}, {
  title: '运费($)',
  dataIndex: 'price',
  key: 'price',
  width: '30%',
  scopedSlots: {
    customRender: 'price'
  }
}];
export { columns };